@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #000000;
  -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Our body on widget mode */
#react-root {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  opacity: 0.4;
}

/* Remove hover on date picker to avoid bad rendering on dark mode. */
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: transparent !important;
}

.grecaptcha-badge {
  z-index: 4;
}

#sending_circle {
  stroke-width: 10px;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  animation: drawcircle 1s 0.5s forwards;
  transform: rotate(-90deg);
  transform-origin: 50%;
}

@keyframes drawcircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes lds-animation {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  80% {
    -webkit-transform: translateY(3.4px);
    transform: translateY(3.4px);
  }
  90% {
    -webkit-transform: translateY(-3.4px);
    transform: translateY(-3.4px);
  }
}

#information-container h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
#information-container h2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
#information-container h3 {
  margin-top: 7px;
  margin-bottom: 7px;
}
#information-container h4 {
  margin-top: 6px;
  margin-bottom: 6px;
}
#information-container h5,
#information-container h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
#information-container hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-tooltip-zindex {
  z-index: 9999999 !important;
}

.PhoneInputInput {
  border: none;
  background: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  outline: none;
}

.captions-on::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  width: 7px;
}

.captions-on::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
